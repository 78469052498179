// Viewports
$viewport-desktop: 1230px;
$viewport-tablet: 768px;
$viewport-mobile: 520px;
$viewport-small: 390px;

// Grid breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1230px,
    xxl: 1420px
) !default;

// Colors
$color-pure-white: #ffffff;
$color-black: #000000;
$color-gray-1: #9c9ea9;
$color-silver-medal: #cecfd3;
$color-sporty-red: #ff003f;
$color-sporty-red-light: #ffebf0;
$color-sporty-red-dark: #b3002c;
$color-deep-night-navy: #080d27;
$color-deep-night-navy-light: #838693;
$color-steam: #eeeef0;
$color-light-breeze: #eaf5ff;
$color-speedy-blue: #0673eb;
$color-btn-focus-outline: #0673eb;

// Typography
$font-family-open-sans: "open sans";
$font-family-inter: "inter";
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

// Animation
$hover-transition-duration: 0.3s;

// Configurable Options (!! Do not change here !!)
$configurable-primary-color: $color-sporty-red;
$configurable-page-bg-color: $color-pure-white;
$configurable-primary-text-color: $color-deep-night-navy;
$configurable-secondary-text-color: $color-deep-night-navy-light;
$configurable-headings-color: $color-deep-night-navy;
$configurable-header-bg-color: $color-pure-white;
$configurable-footer-bg-color: $color-pure-white;
$configurable-footer-link-color: $color-deep-night-navy;
$configurable-footer-link-hover-color: $color-deep-night-navy;
$configurable-primary-btn-bg-color: $configurable-primary-color;
$configurable-primary-btn-bg-hover-color: $color-sporty-red-dark;
$configurable-primary-btn-text-color: $color-pure-white;
$configurable-primary-btn-text-hover-color: $configurable-primary-btn-text-color;
$configurable-primary-btn-border-color: $configurable-primary-color;
$configurable-primary-btn-border-hover-color: $configurable-primary-btn-bg-hover-color;
$configurable-secondary-btn-bg-color: $color-pure-white;
$configurable-secondary-btn-bg-hover-color: $color-sporty-red-light;
$configurable-secondary-btn-text-color: $configurable-primary-color;
$configurable-secondary-btn-text-hover-color: $configurable-secondary-btn-text-color;
$configurable-secondary-btn-border-color: $configurable-primary-color;
$configurable-secondary-btn-border-hover-color: $configurable-secondary-btn-text-color;
$configurable-tertiary-btn-text-color: $color-sporty-red;
$configurable-tertiary-btn-text-hover-color: $color-sporty-red-dark;
$configurable-link-color: $configurable-primary-color;
$configurable-link-hover-color: $color-sporty-red-dark;
$configurable-main-font-name: $font-family-open-sans;

// Colors only to use for landing page
$color-landingpage-pink: $configurable-primary-color;
$color-landingpage-grey: $color-silver-medal;
$color-landingpage-blue: $color-speedy-blue;
$color-landingpage-breeze: $color-light-breeze;
$color-landingpage-black: $color-deep-night-navy;

:root {
    --primary-color: #{$configurable-primary-color};
    --page-bg-color: #{$configurable-page-bg-color};
    --primary-text-color: #{$configurable-primary-text-color};
    --secondary-text-color: #{$configurable-secondary-text-color};
    --headings-color: #{$configurable-headings-color};
    --header-bg-color: #{$configurable-header-bg-color};
    --footer-bg-color: #{$configurable-footer-bg-color};
    --footer-link-color: #{$configurable-footer-link-color};
    --footer-link-hover-color: #{$configurable-footer-link-hover-color};
    --primary-btn-bg-color: #{$configurable-primary-btn-bg-color};
    --primary-btn-bg-hover-color: #{$configurable-primary-btn-bg-hover-color};
    --primary-btn-text-color: #{$configurable-primary-btn-text-color};
    --primary-btn-text-hover-color: #{$configurable-primary-btn-text-hover-color};
    --primary-btn-border-color: #{$configurable-primary-btn-border-color};
    --primary-btn-border-hover-color: #{$configurable-primary-btn-border-hover-color};
    --secondary-btn-bg-color: #{$configurable-secondary-btn-bg-color};
    --secondary-btn-bg-hover-color: #{$configurable-secondary-btn-bg-hover-color};
    --secondary-btn-text-color: #{$configurable-secondary-btn-text-color};
    --secondary-btn-text-hover-color: #{$configurable-secondary-btn-text-hover-color};
    --secondary-btn-border-color: #{$configurable-secondary-btn-border-color};
    --secondary-btn-border-hover-color: #{$configurable-secondary-btn-border-hover-color};
    --tertiary-btn-text-color: #{$configurable-tertiary-btn-text-color};
    --tertiary-btn-text-hover-color: #{$configurable-tertiary-btn-text-hover-color};
    --link-color: #{$configurable-link-color};
    --link-hover-color: #{$configurable-link-hover-color};
    --main-font-name: #{$configurable-main-font-name};
}
