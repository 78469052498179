@import "../variables";
.landingpage {
    padding-top: 40px;
    max-width: calc(100% - 92px);
    min-width: 1548px;

    .row {
        .logo-text {
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: end;
            align-items: center;
            text-align: center;
            margin-bottom: 60px;
            width: 29%;

            .logo {
                margin-bottom: 20px;
            }

            .text {
                margin: 8px 7px;
                color: $color-landingpage-black;
                font-size: 18px;
                font-weight: 400;
                line-height: 27px;
                font-family: $font-family-inter;

                p {
                    margin-bottom: 27px;
                }
            }
        }
    }

    .hexagon-wrap, .logo-text {
        padding-bottom: 90px;
    }

    .hexagon-wrap {
        font-family: $font-family-open-sans;
        width: 35%;

        .row {
            margin-bottom: 32px;

            .polygon {
                display: inline-block;
                position: relative;
                width: 25%;
                transform: scale(1.2024);
                max-width: 150px;
                height: 108.12px;
                padding: 0;
                stroke-width: 3px;
                box-sizing: border-box;
                clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);

                &:first-child {
                    top: 211px;
                }

                &:nth-child(2) {
                    top: 141px;
                }

                &:nth-child(3) {
                    top: 71px;
                }

                &:hover {
                    .image {
                        opacity: 1;
                    }

                    .title {
                        color: transparent;
                    }

                    &:before {
                        z-index: 2;
                        content: "";
                        width: 100%;
                        height: 100%;
                        display: block;
                        right: 0;
                        position: absolute;
                    }
                }

                &.empty {
                    opacity: 0;
                }

                &.sportarten {
                    background: $color-landingpage-pink;
                    stroke: $color-landingpage-pink;

                    &:hover:before {
                        background-color: rgba($color-landingpage-pink, 0.5);
                    }

                    .title {
                        color: $color-landingpage-pink;
                    }
                }

                &.ort {
                    background: $color-landingpage-grey;
                    stroke: $color-landingpage-grey;

                    &:hover:before {
                        background-color: rgba($color-landingpage-grey, 0.5);
                    }

                    .title {
                        color: $color-landingpage-grey;
                    }
                }

                &.target-group {
                    background: $color-landingpage-blue;
                    stroke: $color-landingpage-blue;

                    &:hover:before {
                        background-color: rgba($color-landingpage-blue, 0.5);
                    }

                    .title {
                        color: $color-landingpage-blue;
                    }
                }

                &.target-group-2 {
                    background: $color-landingpage-breeze;
                    stroke: $color-landingpage-breeze;

                    &:hover:before {
                        background-color: rgba($color-landingpage-breeze, 0.5);
                    }

                    .title {
                        color: $color-landingpage-black;
                    }
                }

                .title {
                    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
                    background: #ffffff;
                    display: flex;
                    justify-content: center;
                    text-align: center;
                    align-items: center;
                    padding: 0 13px;

                    p {
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        margin-bottom: 0;
                        -ms-hyphens: auto;
                        -moz-hyphens: auto;
                        -webkit-hyphens: auto;
                        hyphens: auto;
                        max-width: 99%;
                    }
                }

                .image {
                    opacity: 0;
                    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
                    transition: 0.5s;
                }

                .title, .image {
                    position: absolute;
                    height: 96%;
                    top: 2%;
                    width: 96%;
                    left: 2%;
                    object-fit: cover;
                }
            }
        }

        &.target_group, &.sports {
            .inner {
                max-width: 475px;
                padding-top: 12px;
            }
        }

        &.target_group {
            .inner {
                margin-left: auto;
            }
        }

        &.sports {
            .inner {
                margin-right: auto;
            }
        }
    }

    .impressum-page {
        .extra-infos {
            margin-top: 20px;
        }
    }

    .white-space-pre-line {
        -ms-hyphens: none;
        -moz-hyphens: none;
        -webkit-hyphens: none;
        hyphens: none;
        white-space: pre-line;
    }
}
